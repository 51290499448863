import ErrorBoundary from '@utils/ErrorBoundary/ErrorBoundary';
import { retry } from '@vivino/js-web-common';
import PropTypes from 'prop-types';
import React, { lazy } from 'react';
import {
  CART_ITEM_SOURCE_TYPES,
  CartItemSourceContext,
} from 'vivino-js/context/CartItemSourceContext';
import { useUserContext } from 'vivino-js/context/UserContext';
import { isVCCountry } from 'vivino-js/helpers/countryHelpers';
import t from 'vivino-js/translationString';
import DynamicModuleLoader from 'vivino-ui/atoms/DynamicModuleLoader';
import { WineBandSkeleton } from 'vivino-ui/atoms/Skeletons';

import ExploreWidget from 'vivino-js/components/ExploreWidget';
import { Grid } from 'vivino-ui/components/Grid';

import ExplorerAds from '../ExplorerAds';
import { getWelcomeLocationName } from './helpers/welcomePage';
import styles from './welcomePage.scss';

const ExploreBandContainer = lazy(() =>
  retry(() =>
    // @ts-ignore
    import(
      /* webpackChunkName: "ExploreBandContainer" */

      'vivino-js/components/ExploreBandContainer'
    )
  )
);
const RecommendedBandContainer = lazy(() =>
  retry(() =>
    // @ts-ignore
    import(
      /* webpackChunkName: "RecommendedBandContainer" */

      '../BandContainer/RecommendedBandContainer'
    )
  )
);
const OffersWineBand = lazy(() =>
  retry(() =>
    // @ts-ignore
    import(
      /* webpackChunkName: "OffersWineBand" */

      'vivino-js/components/OffersWineBand'
    )
  )
);
const TopListBandWithControls = lazy(() =>
  retry(() =>
    // @ts-ignore
    import(
      /* webpackChunkName: "TopListBandWithControls" */

      '../BandContainer/TopListBandWithControls.container'
    )
  )
);
const ToplistBandsContainer = lazy(() =>
  retry(() =>
    // @ts-ignore
    import(
      /* webpackChunkName: "ToplistBandsContainer" */

      '../BandContainer/ToplistBandsContainer'
    )
  )
);
// const UserRatingBandContainer = lazy(() =>
//   retry(() =>
//     // @ts-ignore
//     import(
//       /* webpackChunkName: "UserRatingBandContainer" */

//       '../BandContainer/UserRatingBandContainer'
//     )
//   )
// );
const AffordableRedsBand = lazy(() =>
  retry(() =>
    // @ts-ignore
    import(
      /* webpackChunkName: "AffordableRedsBand" */

      '../AffordableRedsBand'
    )
  )
);
const OfferSubscription = lazy(() =>
  retry(() =>
    // @ts-ignore
    import(
      /* webpackChunkName: "OfferSubscription" */

      'vivino-js/components/OfferSubscription'
    )
  )
);

const TRANSLATIONS = {
  discover_the_right_wine: 'components.welcome_page.non_market.discover_the_right_wine',
};

const WelcomePage = ({
  countryCode,
  currencyCode,
  priceRange,
  recentPriceRange,
  recentWineStyleIds,
  userCountryCode,
  countryTopLists,
}) => {
  const { user, signedIn } = useUserContext();

  if (!isVCCountry(countryCode) && !isVCCountry(userCountryCode)) {
    return (
      <>
        <div className={styles.nonMarketContainer}>
          <h1 className={styles.nonMarketHeadline}>{t(TRANSLATIONS.discover_the_right_wine)}</h1>
        </div>
        <Grid overflow>
          <CartItemSourceContext.Provider value={CART_ITEM_SOURCE_TYPES.WINEFRONTPAGE}>
            <ExploreWidget priceRange={priceRange} />
          </CartItemSourceContext.Provider>
        </Grid>
      </>
    );
  }

  const location = getWelcomeLocationName();

  const generateBestPicksBand = () => {
    return (
      <DynamicModuleLoader skeleton={<WineBandSkeleton isWithHeaderText />}>
        <CartItemSourceContext.Provider value={CART_ITEM_SOURCE_TYPES.FRONTPAGE_OFFERS}>
          <OffersWineBand countryCode={countryCode} />
        </CartItemSourceContext.Provider>
      </DynamicModuleLoader>
    );
  };

  const generateBestsellersBand = () => {
    return (
      isVCCountry(countryCode) && (
        <DynamicModuleLoader skeleton={<WineBandSkeleton isWithHeaderText />}>
          <CartItemSourceContext.Provider value={CART_ITEM_SOURCE_TYPES.FRONTPAGE_VC_TOPLIST}>
            <ToplistBandsContainer
              toplistsProps={{
                filter: 'vc',
                includeItems: true,
                limit: 4,
                startAt: 0,
              }}
              listType={'default'}
              trackingSource={'frontpage'}
            />
          </CartItemSourceContext.Provider>
        </DynamicModuleLoader>
      )
    );
  };

  const generateExplorerAdsSection = () => {
    return (
      <ErrorBoundary>
        <ExplorerAds priceRange={priceRange} />
      </ErrorBoundary>
    );
  };

  const generateSimilarViewedBand = () => {
    return (
      recentWineStyleIds?.length > 0 &&
      recentPriceRange && (
        <DynamicModuleLoader skeleton={<WineBandSkeleton />}>
          <CartItemSourceContext.Provider value={CART_ITEM_SOURCE_TYPES.FRONTPAGE_SIMILAR_VIEWED}>
            <ExploreBandContainer
              headline={t('welcome.index.related_to_wines_youve_viewed')}
              trackingSource="frontpage"
              trackingName="related-to-viewed-wines"
              wineStyleIds={recentWineStyleIds}
              priceRange={recentPriceRange}
            />
          </CartItemSourceContext.Provider>
        </DynamicModuleLoader>
      )
    );
  };

  const generateForYouBand = () => {
    return (
      signedIn && (
        <DynamicModuleLoader skeleton={<WineBandSkeleton />}>
          <CartItemSourceContext.Provider value={CART_ITEM_SOURCE_TYPES.FRONTPAGE_JUST_FOR_YOU}>
            <RecommendedBandContainer
              headline={t('welcome.index.just_for_you', {
                name: user.short_name,
              })}
              description={t('welcome.index.a_weekly_set_of_wines_you_did_not_know_you_wanted')}
              listType="recommended"
              minimumItemNumber={1}
              trackingSource="frontpage"
              trackingName="recommended"
              userImage={user.image?.variations?.large}
            />
          </CartItemSourceContext.Provider>
        </DynamicModuleLoader>
      )
    );
  };

  const generateAffordableRedsBand = () => {
    return (
      <DynamicModuleLoader skeleton={<WineBandSkeleton isWithHeaderText />}>
        <CartItemSourceContext.Provider value={CART_ITEM_SOURCE_TYPES.FRONTPAGE_AFFORDABLE}>
          <AffordableRedsBand priceRange={priceRange} />
        </CartItemSourceContext.Provider>
      </DynamicModuleLoader>
    );
  };

  const generateOffersSubscriptionSection = () => {
    return (
      isVCCountry(countryCode) && (
        <DynamicModuleLoader>
          <OfferSubscription countryCode={countryCode} />
        </DynamicModuleLoader>
      )
    );
  };

  const generateTopListsBand = () => {
    return (
      countryTopLists &&
      countryTopLists.length > 0 && (
        <DynamicModuleLoader skeleton={<WineBandSkeleton isWithHeaderText />}>
          <CartItemSourceContext.Provider
            value={CART_ITEM_SOURCE_TYPES.FRONTPAGE_COMPUTATIONAL_TOPLIST}
          >
            <TopListBandWithControls
              topLists={countryTopLists}
              currencyCode={currencyCode}
              trackingSource="frontpage"
              trackingName="country-toplists"
              headline={t('welcome.index.top_lists_in_location', { location })}
              description={t('welcome.index.updated_every_thursday')}
            />
          </CartItemSourceContext.Provider>
        </DynamicModuleLoader>
      )
    );
  };

  const generateWelcomePage = () => {
    return (
      <Grid>
        {generateBestPicksBand()}
        {generateBestsellersBand()}
        {generateExplorerAdsSection()}
        {generateSimilarViewedBand()}
        {generateForYouBand()}
        {generateAffordableRedsBand()}
        {generateOffersSubscriptionSection()}
        {generateTopListsBand()}
      </Grid>
    );
  };

  return <div>{generateWelcomePage()}</div>;
};

WelcomePage.propTypes = {
  countryCode: PropTypes.string,
  currencyCode: PropTypes.string,
  priceRange: PropTypes.object,
  recentPriceRange: PropTypes.object,
  recentWineStyleIds: PropTypes.array,
  userCountryCode: PropTypes.string,
  countriesWithoutReferralProgram: PropTypes.array,
  countryTopLists: PropTypes.array,
  promotions: PropTypes.array,
};

export default WelcomePage;
